/* PASA */
.promotion-detail .checkbox {
    margin-right: 15px;
}

.titleDiv {
    background-color: #000;
    padding: 20px;
    margin-bottom: 10px;
}

.App .ui.table td.positive,
.App .ui.table tr.positive {
    background: #fcffb0 !important;
    color: #2c662d !important;
}

/* CostCalculationsTable */
.ui.table.CostCalculationsTable thead th {
    font-size: 12px;
    color: rgba(0, 0, 0, .6);
    padding: 4px 10px;
    white-space: pre;
}

.ui.table.CostCalculationsTable tbody td {
    padding: 4px 10px;
    white-space: pre;
}

.ui.table.CostCalculationsTable tfoot tr th {
    padding: 4px 10px;
    white-space: pre;
}

.ui.table.CostCalculationsTableEdit thead th {
    font-size: 12px;
    color: rgba(0, 0, 0, .6);
    padding: 4px 10px;
    white-space: pre;
}

.ui.table.CostCalculationsTableEdit tbody td {
    padding: 4px 4px;
    white-space: pre;
}

.ui.table.CostCalculationsTableEdit tbody input {
    padding: 2px 2px;
    margin: -2px 0;
    min-width: 40px;
}

.ui.table.CostCalculationsTableEdit tbody .labeled.input .label {
    padding: 4px 2px;
    margin: -2px 0;
}

.ui.table.CostCalculationsTableEdit tfoot th {
    padding: 4px 4px;
    white-space: pre;
}

.ui.table.CostCalculationsTableEdit tfoot input {
    padding: 2px 2px;
    margin: -2px 0;
    min-width: 40px;
}

.ui.table.CostCalculationsTableEdit tfoot .labeled.input .label {
    padding: 4px 2px;
    margin: -2px 0;
}

.ui.table.commonTable {
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
}

.ui.table.commonTable thead th {
    font-size: 12px;
    color: rgba(0, 0, 0, .6);
    background-color: #e8e8e8;
    padding: 5px 1em;
    white-space: nowrap;
}

.ui.table.commonTable tbody td {
    padding: 5px 1em;
    white-space: nowrap;
}

.ui.table.commonTableEdit thead th {
    font-size: 12px;
    color: rgba(0, 0, 0, .6);
    padding: 4px 10px;
    white-space: nowrap;
}

.ui.table.commonTable tbody td {
    padding: 5px 1em;
    white-space: nowrap;
}

.ui.table.commonTable tbody input {
    padding: .67857143em 2px;
    margin: -2px 0;
    min-width: 40px;
}

.Error-Zone {
    background-color: #fff6f6 !important;
    border-color: #e0b4b4 !important;
    color: #9f3a38 !important;
}

.customDatePick {
    opacity: 0.4 !important;
    background-color: #dfdfdf !important;
}

.ui[class*="PercentageInput"].input>input {
    text-align: right;
}

.ui[class*="disabled"].input>input {
    background: #dfdfdf;
}

.ui[class*="disabled"].selection.dropdown {
    background: #dfdfdf !important;
}

.ui[class*="disabled"].checkbox .box:before,
.ui[class*="disabled"].checkbox label:before {
    background: #dfdfdf !important;
}

.ui.table.requestorTable {
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 0;
}

.ui.table.requestorTable thead th {
    font-size: 12px;
    color: rgba(0, 0, 0, .6);
    background-color: #e8e8e8;
    padding: 5px 1em;
    white-space: nowrap;
}

.ui.table.requestorTable tbody td {
    padding: 5px 1em;
    white-space: nowrap;
}

.ui.table.requestorTable tbody td {
    padding: 5px 1em;
    white-space: nowrap;
}

.Costcolc_bgcolor {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    padding: 0px !important;
}

.link_style {
    color: blue;
    text-decoration: underline;
}

.link_style:hover {
    cursor: pointer;
}

.flex {
    display: flex;
    margin-top: 10px;
}

.flex-floatRight {
    margin-left: auto !important;
}

.flex-floatLeft {
    margin-right: auto !important;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.disabled-input {
    background-color: rgba(0, 0, 0, 0.25);
}

/* size='fullscreen'のモーダルのCSSを上書き */
#fullscreenModal {
    left: 2.5% !important;
}

dt {
    font-weight: bold;
    text-decoration: underline;
}

/* ModalのActionの角を丸く */
.ui.modal>.actions {
    border-radius: inherit;
}

.margin-btm {
    margin-bottom: 5px !important;
}

pre {
    font-family: inherit !important;
}

/* Semantic UIと同じ必須マーク */
span.required::after {
    margin: -0.2em 0 0 0.2em;
    content: '*';
    color: #db2828;
}

/* Weight & Ship tracking #のリスト */
ol.weight-shipTrackingNumber {
    list-style: none;
    counter-reset: li_count;
    padding: 0;
    margin: 0;
}

ol.weight-shipTrackingNumber li::before {
    counter-increment: li_count;
    content: 'Box ' counter(li_count)': ';
}

/* Semantic UIのエラーの文字色 */
p.red {
    color: #9f3a38;
}

/* メニューバーのDropdownの文字色 */
.ui.menu .ui.dropdown.inverted>.text {
    color: rgba(255, 255, 255, .7) !important;
}

.ui.menu .ui.dropdown.inverted:hover>.text {
    color: #FFF !important;
}

.ui.menu .ui.dropdown.inverted.active>.text {
    color: #FFF !important;
}

.ui.menu .ui.dropdown.inverted .menu {
    background-color: #1b1c1d !important;
    border: 2px solid #888;
}

.ui.menu .ui.dropdown.inverted .menu>.item {
    color: rgba(255, 255, 255, .7) !important;
}

.ui.menu .ui.dropdown.inverted .menu>.item:hover {
    background: rgba(255, 255, 255, .08) !important;
    color: #fff !important;
}

.ui.menu .ui.dropdown.inverted .menu>.selected.item {
    background-color: rgba(255, 255, 255, .15) !important;
    color: #fff !important;
}

.sub.header::before {
    height: auto !important;
}

.endUserNote {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.maintainAspectRatio {
    object-fit: contain;
}

#radioAndRepeaterImage {
    width: 200px;
    height: auto;
}

/* For the "Under preparation" label. Delete them together. */
.ui.card>.content>.header,
.ui.cards>.card>.content>.header {
    margin: 0.2em;
}
